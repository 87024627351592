<template>
  <NuxtLayout>
   <div class="flex flex-col bg-gray-950">
    <div v-if="showRibbon" class=" items-center gap-x-1 bg-gray-900 px-6 py-2.5 sm:px-3.5 before:flex-1 z-50 flex fixed w-full">
    <div class="text-sm leading-6 text-white flex items-center gap-2">
      <div class="sm:flex gap-2 items-center flex	w-max">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4 text-indigo-500">
          <path fill-rule="evenodd" d="M5 4a.75.75 0 0 1 .738.616l.252 1.388A1.25 1.25 0 0 0 6.996 7.01l1.388.252a.75.75 0 0 1 0 1.476l-1.388.252A1.25 1.25 0 0 0 5.99 9.996l-.252 1.388a.75.75 0 0 1-1.476 0L4.01 9.996A1.25 1.25 0 0 0 3.004 8.99l-1.388-.252a.75.75 0 0 1 0-1.476l1.388-.252A1.25 1.25 0 0 0 4.01 6.004l.252-1.388A.75.75 0 0 1 5 4ZM12 1a.75.75 0 0 1 .721.544l.195.682c.118.415.443.74.858.858l.682.195a.75.75 0 0 1 0 1.442l-.682.195a1.25 1.25 0 0 0-.858.858l-.195.682a.75.75 0 0 1-1.442 0l-.195-.682a1.25 1.25 0 0 0-.858-.858l-.682-.195a.75.75 0 0 1 0-1.442l.682-.195a1.25 1.25 0 0 0 .858-.858l.195-.682A.75.75 0 0 1 12 1ZM10 11a.75.75 0 0 1 .728.568.968.968 0 0 0 .704.704.75.75 0 0 1 0 1.456.968.968 0 0 0-.704.704.75.75 0 0 1-1.456 0 .968.968 0 0 0-.704-.704.75.75 0 0 1 0-1.456.968.968 0 0 0 .704-.704A.75.75 0 0 1 10 11Z" clip-rule="evenodd" />
        </svg>
        <p class="">        
        <span class="font-semibold">API Just Launched   </span> <span class="hidden sm:inline	"> • Integrate color & URL extraction into your app</span></p>
      </div>
      <div class="flex gap-2">
        <a type="button" class="transition w-max sm:ml-1 gap-1 justify-center content-center flex rounded bg-white/10 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-white/20 cursor-pointer" @click="apiRibbonClicked()">
          Get API Key
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
            <path fill-rule="evenodd" d="M2 8c0 .414.336.75.75.75h8.69l-1.22 1.22a.75.75 0 1 0 1.06 1.06l2.5-2.5a.75.75 0 0 0 0-1.06l-2.5-2.5a.75.75 0 1 0-1.06 1.06l1.22 1.22H2.75A.75.75 0 0 0 2 8Z" clip-rule="evenodd" />
          </svg>
        </a>
      </div>
    </div>
    <div class="flex flex-1 justify-end">
      <button type="button" class="-m-3 p-3 focus-visible:outline-offset-[-4px]" @click.prevent="showRibbon=false">
        <span class="sr-only">Dismiss</span>
        <XMarkIcon class="h-5 w-5 text-white" aria-hidden="true" />
      </button>
    </div>
  </div>
      <!-- Global notification live region, render this permanently at the end of the document -->
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <Notification :showNotification="showNotification" message="Palette Link Copied!" :autoHideDuration="2000" @update:showNotification="showNotification = false" />
      <Notification :showNotification="showNotificationColor" message="Color Copied!" :autoHideDuration="2000" @update:showNotification="showNotificationColor = false" />
      <Notification :showNotification="showNotificationColorAdded" message="Color added to palette!" :autoHideDuration="2000" @update:showNotification="showNotificationColorAdded = false" />
      <transition  v-if="showFilter && totalCodeSelectedColors+totalVisualSelectedColors !== 0 && enableEdit === true" enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div class="pointer-events-auto flex w-full max-w-md divide-x divide-gray-200 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="flex w-0 flex-1 items-center p-4">
            <div class="w-full">
              <p class="text-sm font-medium text-gray-900">You have selected <span class="font-extrabold">{{totalVisualSelectedColors + totalCodeSelectedColors}}</span> colors</p>
              <p class="mt-1 text-sm text-gray-500 hidden sm:block">Filter and keep only selected colors in your palettes.</p>
            </div>
          </div>
          <div class="flex">
            <div class="flex flex-col divide-y divide-gray-200">
              <div class="flex h-0 flex-1">
                <button type="button" @click="mergePalettes" class="flex w-full items-center justify-center rounded-none rounded-tr-lg border border-transparent px-4 py-3 text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500">Keep <span class="font-extrabold mx-1">{{totalVisualSelectedColors + totalCodeSelectedColors}}</span> Colors</button>
              </div>
              <div class="flex h-0 flex-1">
                <button type="button" @click="resetFiltering" class="flex w-full items-center justify-center rounded-none rounded-br-lg border border-transparent px-4 py-3 text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
      <div class="relative flex-none overflow-hidden px-6 lg:pointer-events-none lg:fixed lg:inset-0 lg:z-40 lg:flex lg:px-0">
         <div class="lg:border-r z-10 flex border-gray-900 border-0 relative w-full lg:pointer-events-auto lg:mr-[calc(max(2rem,50%-38rem)+40rem)] lg:min-w-[32rem] lg:overflow-y-auto lg:overflow-x-hidden lg:pl-[max(4rem,calc(50%-38rem))]">
            <div class="mx-auto max-w-lg lg:mx-0 lg:flex lg:w-96 lg:max-w-none lg:flex-col lg:before:flex-1 lg:before:pt-6">
               <div class="">
                <BackgroundTile></BackgroundTile>
                  <div class="relative lg:my-6 my-24">
                    <a href="/">
                    <img width="250px" src="../assets/img/colorize_design_logo.svg" alt="colorize.design" />
                    </a>
                    <transition 
                      enter-active-class="transition-opacity duration-500"
                      leave-active-class="transition-opacity duration-500"
                      enter-from-class="opacity-0"
                      enter-to-class="opacity-100"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
            <h1 v-if="screenshotLoaded===false" class="transition-all mt-10 text-4xl font-bold tracking-tight text-white sm:text-5xl ">Generate <span class="bg-gradient-to-r from-violet-500 via-blue-50 to-indigo-400 text-transparent bg-clip-text">color palettes</span> from any website.</h1>
          </transition>
          <transition 
                      enter-active-class="transition-opacity duration-500"
                      leave-active-class="transition-opacity duration-500"
                      enter-from-class="opacity-0"
                      enter-to-class="opacity-100"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
            <h2 v-if="screenshotLoaded===false" class="transition-all mt-6 text-lg leading-8 text-gray-300">Extract grouped color combos from any website. Enter a URL to get a design palette.</h2>
          </transition>
            <div class="mt-6 flex items-center w-full transition-all" >
              <div data-clarity-unmask="true" class="transition-all w-full">
                <div data-clarity-unmask="true" class="z-40 transition-all w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-700 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 bg-gray-900 items-center" :class="{ 'ring-red-700': hasError, 'focus-within:ring-red-700': hasError}">
                  <div class="h-6 w-6 ml-2.5">
                      <img v-if="typedUrl=='' && isLoading===false" class="min-h-6 min-w-6 h-6 w-6" src="../assets/img/palettes_icon_bw.svg" alt="favicon" />
                      <img v-if="typedUrl!=='' && isLoading===false && response.favicon !== ''" class="min-h-6 min-w-6 rounded " :src="response.favicon" alt="favicon"/>
                      <img v-if="response.favicon === '' && isLoading===false" class="min-h-6 min-w-6 h-6 w-6" src="../assets/img/palettes_icon_bw.svg" alt="favicon" />
                      <div v-if="isLoading===true">
                        <div class="animate-pulse mx-auto rounded-full bg-slate-700 h-6 w-6"></div>                                     
                      </div>
                    </div>
                  <input @keypress.enter="getUrl()" data-clarity-unmask="true" v-model="urlData" type="text" name="company-website" id="company-website" class="w-full block flex-1 border-0 bg-transparent px-2 text-white placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"  placeholder="www.example.com" />
                  <button @click.prevent="getUrl()" type="button" class="transition m-2 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-gray-500">
                    <div class="flex">
                      <div v-if="isLoading" class="flex">
                      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Generating Palette
                      </div>
                      <div v-if="!isLoading">Generate Palette</div>
                    </div>
                  </button>
                </div>
                <div class="relative flex justify-center">
                    <button @click.prevent="toggleSettings()" type="button" class="transition inline-flex items-center gap-x-1 rounded-b-md bg-gray-900 px-3 py-1 text-sm font-semibold text-gray-400 shadow-sm border-x border-b border-gray-800 hover:bg-gray-800">
                      <PlusIcon v-if="!settingToggle" class="-ml-1 -mr-0.5 h-4 w-4 text-gray-400" aria-hidden="true"  />
                      <MinusIcon v-if="settingToggle" class="-ml-1 -mr-0.5 h-4 w-4 text-gray-400" aria-hidden="true"  />
                      <p class="text-xs">Settings</p>
                    </button>
                  </div>

                <NuxtTurnstile ref="turnstile" v-model="token"/>

                <span v-if="hasError || hasWarning || hasSuccess" class="mt-2 inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-gray-800">
                  <svg 
                    :class="{
                      'h-1.5 w-1.5 fill-red-400': hasError,
                      'h-1.5 w-1.5 fill-yellow-400': !hasError && hasWarning,
                      'h-1.5 w-1.5 fill-green-400': !hasError && !hasWarning && hasSuccess
                    }" 
                    viewBox="0 0 6 6" 
                    aria-hidden="true"
                  >
                    <circle cx="3" cy="3" r="3" />
                  </svg>
                  {{ errorMsg }}
                </span>

                <div class="relative">
                  <!-- <div class="absolute inset-0 flex items-center" aria-hidden="true">
                    <div class="w-full border-t border-gray-800"> </div>
                  </div> -->

                </div>
                <div v-if="settingToggle" class="transition">
                <p class="text-gray-200 font-semibold mt-2">Extract colors from:</p>
                <div class="w-full">
                  <div>
                    <div
                      class="mt-4 grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-4"
                      :class="{ 'pointer-events-none opacity-50': isLoading }"
                    >
                      <div
                        as="template"
                        v-for="mailingList in mailingLists"
                        :key="mailingList.id"
                        :value="mailingList"
                      >
                        <div
                          :class="[
                            mailingList.checked ? 'transition ring-2 ring-indigo-500' : 'transition ring-0',
                            'relative flex cursor-pointer rounded-lg bg-gray-900 p-4 shadow-sm focus:outline-none hover:bg-gray-800',
                          ]"
                          @click="!isLoading && toggleCheck(mailingList)"
                        >
                          <span class="flex flex-1">
                            <span class="flex flex-col">
                              <div as="span" class="block text-sm font-medium text-white">
                                {{ mailingList.title }}
                              </div>
                              <div as="span" class="mt-1 block text-sm text-gray-400">
                                {{ mailingList.description }}
                              </div>
                              <div class="mt-1 flex gap-6">
                                <div v-if="mailingList.sliderCapable" class="w-10 text-sm text-gray-400">
                                  Colors:&nbsp;{{ mailingList.currentVal }}
                                </div>
                                <input
                                  v-if="mailingList.sliderCapable"
                                  v-model="mailingList.currentVal"
                                  type="range"
                                  min="2"
                                  max="12"
                                  step="1"
                                  class="w-full mt-2 h-1 rounded-lg appearance-none cursor-pointer bg-gray-700 accent-indigo-600"
                                  @click.stop
                                  :disabled="isLoading"
                                />
                              </div>
                            </span>
                          </span>
                          <CheckCircleIcon
                            :class="[!mailingList.checked ? 'invisible' : '', 'h-5 w-5 text-indigo-500']"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div v-if="mailingLists.find(item => item.id === 2)?.checked" class="mt-10 rounded-lg transition-all opacity-100" :class="{ 'hidden opacity-0': isLoading === false && typedUrl === '', 'mt-3': screenshotLoaded }">
                  <div class="relative border-x border-t rounded-t-md border-gray-800 " :class="{'overflow-hidden' :screenshotAnimation && screenshotLoaded, 'border-b rounded-b-md':screenshotLoaded}">
                    <transition 
                      enter-active-class="transition-opacity duration-600"
                      leave-active-class="transition-opacity duration-600"
                      enter-from-class="opacity-0"
                      enter-to-class="opacity-20"
                      leave-from-class="opacity-20"
                      leave-to-class="opacity-0"
                    >
                    <img 
                    v-if="screenshotLoaded===false"
                      src="../assets/img/screenshot.png" 
                      alt="screenshot"
                      class="w-full rounded-md blur-sm opacity-20"
                    />
                    </transition>
                  

                <canvas ref="canvas" class="hidden"></canvas>

                  <img class="screenshot-image transition-opacity delay-700 duration-700 ease-in-out rounded-md max-w-full cursor-crosshair " :class="{'opacity-100': screenshotLoaded, 'opacity-0': !screenshotLoaded, 'blur-md': screenshotAnimation}" :src="response.screenshot" alt="" ref="image" crossorigin="anonymous" @contextmenu.prevent @mousemove="handleMouseMove" @mouseleave="hideMagnifier" @click="handleClick" @load="screenshotLoaded=true; screenshotAnimation=false"  @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
                  <div
                      v-if="showMagnifier"
                      class="magnifier pt-5 min-w-max"
                      :style="{
                        top: magnifierY + 'px',
                        left: magnifierX + 'px',
                        backgroundColor: selectedColor,
                      }"
                    >
                    <img src="../assets/img/belly.svg" class="w-full"> 
                    <p class="text-xs bg-white p-2 font-semibold text-gray-800">{{ selectedColor }}</p>
                    
                    <p class="text-xs bg-white p-2 pt-1 font-semibold text-gray-600 flex min-w-max">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
                      <path d="M7.25 1.75a.75.75 0 0 1 1.5 0v1.5a.75.75 0 0 1-1.5 0v-1.5ZM11.536 2.904a.75.75 0 1 1 1.06 1.06l-1.06 1.061a.75.75 0 0 1-1.061-1.06l1.06-1.061ZM14.5 7.5a.75.75 0 0 0-.75-.75h-1.5a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 .75-.75ZM4.464 9.975a.75.75 0 0 1 1.061 1.06l-1.06 1.061a.75.75 0 1 1-1.061-1.06l1.06-1.061ZM4.5 7.5a.75.75 0 0 0-.75-.75h-1.5a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 .75-.75ZM5.525 3.964a.75.75 0 0 1-1.06 1.061l-1.061-1.06a.75.75 0 0 1 1.06-1.061l1.061 1.06ZM8.779 7.438a.75.75 0 0 0-1.368.366l-.396 5.283a.75.75 0 0 0 1.212.646l.602-.474.288 1.074a.75.75 0 1 0 1.449-.388l-.288-1.075.759.11a.75.75 0 0 0 .726-1.165L8.78 7.438Z" />
                    </svg>
                      <span v-if="isMobile===false">Click to sample</span>
                      <span v-if="isMobile===true">Release to sample</span> </p>
                  </div>
                    <!-- Gradient Overlay -->
                    <transition 
                      enter-active-class="transition-opacity duration-600"
                      leave-active-class="transition-opacity duration-600"
                      enter-from-class="opacity-0"
                      enter-to-class="opacity-100"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                    <div v-if="screenshotLoaded!==true" class="absolute -bottom-3 left-0 w-[110%] h-20 bg-gradient-to-t from-gray-950 to-transparent -ml-2"></div>
                    </transition>
                    <div v-if="screenshotPromtShow === true" class="absolute top-0 left-1/2 -translate-x-1/2 bg-gray-900 text-white px-2.5 rounded-b-md text-xs flex items-center gap-1 py-1 text-gray-400 size-max">
                    <div v-if="!screenshotAnimation">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" class="size-3.5 text-gray-400">
                      <path d="M17.4714 13.4473L10.3901 6.38467L11.8032 4.96944C12.5839 5.74907 13.8509 5.74798 14.6296 4.96419L16.2145 3.45074C16.8427 2.82098 17.6808 2.52914 18.5036 2.57396C20.1672 2.66459 21.4413 4.09402 21.3522 5.72959C21.3128 6.45152 21.0035 7.14773 20.4643 7.68919L18.8794 9.20164C18.1007 9.98444 18.1009 11.2493 18.8835 12.0311L17.4714 13.4473Z"/>
                      <path d="M7.22915 17.5933L15.9338 8.9137L16.9013 9.8784L8.11705 18.6508C7.16908 19.6001 6.59031 18.7831 5.35867 19.6274C5.19383 19.7395 5.01968 19.784 4.85529 19.7751C4.44532 19.7527 4.09518 19.3984 4.11974 18.9475C4.12784 18.7988 4.17891 18.6401 4.28006 18.4787C5.01375 17.2934 4.29045 16.6806 5.21442 15.7574L13.9987 6.98362L14.9663 7.94832L6.26162 16.6286C6.03434 16.857 6.08236 17.5329 5.94445 17.9044C6.31536 17.7645 7.00188 17.8217 7.22915 17.5933Z"/>
                      <rect x="14.1211" y="8.46484" width="2" height="13" transform="rotate(45 14.1211 8.46484)"/>
                      </svg>
                    </div>
                      <div v-if="screenshotAnimation" class="flex">
                                  <svg class="animate-spin ml-1 h-3 w-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                  </svg>
                      </div>
                      <p v-if="screenshotAnimation===true" class="text-gray-400">Screenshot loading</p>
                      <p v-if="screenshotAnimation===false && screenshotLoaded===false" class="text-gray-400">Screenshot will appear here</p>
                      <p v-if="screenshotAnimation===false && screenshotLoaded!==false && isMobile===false" class="text-gray-400">Hover to add colors</p>
                      <p v-if="screenshotAnimation===false && screenshotLoaded!==false && isMobile===true" class="text-gray-400">Tap & hold to add colors</p>
                    </div>
                  </div>
                
                </div>
              </div>

            </div>
                  </div>
               </div>
               <div class="flex  flex-1 items-end justify-center pb-4 lg:justify-start lg:pb-6">
                  <p class="flex items-center gap-x-2 text-[0.8125rem]/6 text-gray-500">
                     Built with love by<!-- --> 
                     <a class="group relative isolate flex items-center rounded-lg px-0.5 py-0.5 text-[0.8125rem]/6 font-medium text-white/30 transition-colors hover:text-sky-300 gap-x-2" href="https://www.nestoras.design" target="_blank"  @click="nestorasClick()">
                        <span class="absolute inset-0 -z-10 scale-75 rounded-lg bg-white/5 opacity-0 transition group-hover:scale-100 group-hover:opacity-100"></span>
                        <img src="../assets/img/logo.svg" alt="logo" width="130" class="m-1.5">
                     </a>
                  </p>
               </div>
            </div>
         </div>
      </div>
         <div class="space-y-8 py-30 sm:space-y-8 sm:py-20 min-h-screen	mb-10 transition-all">

          <div class="scroll-mt-16" ref="scrollElement">
                  <div class="mx-auto max-w-7xl px-6 lg:flex lg:px-8 opacity-100" :class="{ 'opacity-40': typedUrl === '' && isLoading === false }">
                     <div class="lg:ml-96 lg:flex lg:w-full lg:justify-end lg:pl-32 ">
                        <div class="mx-auto max-w-lg lg:mx-0 lg:w-0 lg:max-w-xl lg:flex-auto typography relative ">
                          
                          <div class="overflow-hidden rounded-lg border border-gray-600 shadow bg-gray-950 ">
                            <div class="overflow-hidden border-gray-600 shadow bg-gray-950 py-2">
                            <div class="relative group w-full">
                              <!-- Display Text -->
                              <div v-if="isLoading === true" class="m-2 flex flex-wrap justify-center gap-8">
                              <div class="animate-pulse h-5 bg-slate-700 rounded w-36 my-1">
                              </div>
                              </div>
                              <h3 v-if="isLoading === false"
                                class="py-1.5 text-center text-lg font-medium text-white cursor-text transition-opacity duration-200 group-hover:opacity-50 leading-10 whitespace-nowrap overflow-hidden text-ellipsis"
                                :class="{ 'invisible': editMode }"
                                @click="activateEditMode"
                              >
                                {{ title }}
                              </h3>

                              <!-- Editable Input -->
                              <input v-if="isLoading === false"
                                ref="inputField"
                                v-model="title"
                                type="text"
                                class="py-1.5 absolute rounded-lg inset-0 w-full text-center text-lg font-medium text-white bg-transparent focus:outline-none focus:ring-0 focus:border-0 placeholder-transparent caret-white"
                                :class="{ 'visible': editMode, 'invisible': !editMode }"
                                @blur="editMode = false"
                              />
                            </div>
                          </div>
                            <div v-if="isLoading === false" class="mx-2.5 mb-2.5 p-1.5 px-6 flex flex-wrap justify-center md:gap-4 gap-1 bg-gray-900 rounded-md">
                              <button class="flex-none group relative isolate flex items-center rounded-lg px-2 py-0.5 text-[0.8125rem]/6 font-medium text-white/30 transition-colors hover:text-sky-300 gap-x-2 cursor-pointer" @click.prevent="openNotification()">
                                <span class="absolute inset-0 -z-10 scale-75 rounded-lg bg-white/5 opacity-0 transition group-hover:scale-100 group-hover:opacity-100"></span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
                                  <path fill-rule="evenodd" d="M8.914 6.025a.75.75 0 0 1 1.06 0 3.5 3.5 0 0 1 0 4.95l-2 2a3.5 3.5 0 0 1-5.396-4.402.75.75 0 0 1 1.251.827 2 2 0 0 0 3.085 2.514l2-2a2 2 0 0 0 0-2.828.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                                  <path fill-rule="evenodd" d="M7.086 9.975a.75.75 0 0 1-1.06 0 3.5 3.5 0 0 1 0-4.95l2-2a3.5 3.5 0 0 1 5.396 4.402.75.75 0 0 1-1.251-.827 2 2 0 0 0-3.085-2.514l-2 2a2 2 0 0 0 0 2.828.75.75 0 0 1 0 1.06Z" clip-rule="evenodd" />
                                </svg>
                                <span class="self-baseline text-white text-md">Share Link</span>
                              </button>
                              <button class="flex-none group relative isolate flex items-center rounded-lg px-2 py-0.5 text-[0.8125rem]/6 font-medium text-white/30 transition-colors hover:text-sky-300 gap-x-2 cursor-pointer" @click.prevent="editToggle()">
                                <span class="absolute inset-0 -z-10 scale-75 rounded-lg bg-white/5 opacity-0 transition group-hover:scale-100 group-hover:opacity-100"></span>
                                <svg v-if="!enableEdit" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4" >
                                  <path fill-rule="evenodd" d="M11.013 2.513a1.75 1.75 0 0 1 2.475 2.474L6.226 12.25a2.751 2.751 0 0 1-.892.596l-2.047.848a.75.75 0 0 1-.98-.98l.848-2.047a2.75 2.75 0 0 1 .596-.892l7.262-7.261Z" clip-rule="evenodd" />
                                </svg>
                                <svg v-if="enableEdit" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="text-red-500 size-4">
                                  <path d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
                                </svg>
                                <span class="self-baseline text-white text-md">
                                  <p v-if="!enableEdit">Edit Colors</p>
                                  <p v-if="enableEdit">Cancel Edit</p>
                                </span>
                              </button>
                              <button ref="dropdownButton" class="flex-none group relative isolate flex items-center rounded-lg px-2 py-0.5 text-[0.8125rem]/6 font-medium text-white/30 transition-colors hover:text-sky-300 gap-x-2 cursor-pointer" @click.prevent="openDropdown()">
                                  <span class="absolute inset-0 -z-10 scale-75 rounded-lg bg-white/5 opacity-0 transition group-hover:scale-100 group-hover:opacity-100"></span>
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentcolor" class="size-4">
                                    <path d="M8.75 2.75a.75.75 0 0 0-1.5 0v5.69L5.03 6.22a.75.75 0 0 0-1.06 1.06l3.5 3.5a.75.75 0 0 0 1.06 0l3.5-3.5a.75.75 0 0 0-1.06-1.06L8.75 8.44V2.75Z" />
                                    <path d="M3.5 9.75a.75.75 0 0 0-1.5 0v1.5A2.75 2.75 0 0 0 4.75 14h6.5A2.75 2.75 0 0 0 14 11.25v-1.5a.75.75 0 0 0-1.5 0v1.5c0 .69-.56 1.25-1.25 1.25h-6.5c-.69 0-1.25-.56-1.25-1.25v-1.5Z" />
                                  </svg>
                                  <span class="self-baseline text-white text-md">Download Palette</span>
                                </button>
                            </div>
                            <div v-if="isLoading === true" class="m-2 flex flex-wrap justify-center gap-8">
                              <div class="animate-pulse h-5 bg-slate-700 rounded w-24 my-1">
                              </div>
                              <div class="animate-pulse h-5 bg-slate-700 rounded w-24 my-1">
                              </div>  
                              <div class="animate-pulse h-5 bg-slate-700 rounded w-24 my-1">
                              </div>      
                            </div>
                          </div>

                          <div v-if="isDropdownOpen" ref="dropdown"  class="absolute right-0 z-10 mt-2 w-52 origin-top-right rounded-md bg-gray-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                                  <div class="py-1 m-2 flex flex-col gap-2" role="none">
                                    <DownloadButton :type="'CSS_VARIABLES'" :category="'palette'" :format="'css'" :buttonText="'CSS'" @download-file="downloadFile"/>
                                    <DownloadButton :type="'SASS_VARIABLES'" :category="'palette'" :format="'sass'" :buttonText="'SASS'" @download-file="downloadFile"/>
                                    <DownloadButton :type="'SVG'" :category="'palette'" :format="'svg'" :buttonText="'SVG'" @download-file="downloadFile" />
                                    <DownloadButton :type="'SKETCH_PALETTE'" :category="'palette'" :format="'sketchpalette'" :buttonText="'Sketch Palette'" @download-file="downloadFile"/>
                                    <DownloadButton :type="'ADOBE_SWATCH_EXCHANGE'" :category="'palette'" :format="'ase'" :buttonText="'Adobe Swatch'" @download-file="downloadFile"/>
                                    <DownloadButton :type="'SCSS_VARIABLES'" :category="'palette'" :format="'scss'" :buttonText="'SCSS'" @download-file="downloadFile"/>
                                    <DownloadButton :type="'GIMP_PALETTE'" :category="'palette'" :format="'gpl'" :buttonText="'GIMP Palette'" @download-file="downloadFile" />
                                    <DownloadButton :type="'ADOBE_COLOR_SWATCH'" :category="'palette'" :format="'aco'" :buttonText="'Adobe Color Swatch'" @download-file="downloadFile" />
                                    <DownloadButton :type="'PAINTDOTNET_PALETTE'" :category="'palette'" :format="'txt'" :buttonText="'PAINT.NET Palette'" @download-file="downloadFile" />
                                    <DownloadButton :type="'ADOBE_COLOR_TABLE_PALETTE'" :category="'palette'" :format="'act'" :buttonText="'Adobe Color Table'" @download-file="downloadFile" />
                                    <DownloadButton :type="'PAINT_SHOP_PRO_PALETTE'" :category="'palette'" :format="'pal'" :buttonText="'Paint Shop Pro Palette'" @download-file="downloadFile" />
                                    <DownloadButton :type="'HOMESITE_PALETTE'" :category="'palette'" :format="'hpl'" :buttonText="'Homesite Palette'" @download-file="downloadFile" />
                                    <DownloadButton :type="'SKENCIL_PALETTE'" :category="'palette'" :format="'spl'" :buttonText="'Skencil Palette'" @download-file="downloadFile" />
                                    <DownloadButton :type="'STAROFFICE_PALETTE'" :category="'palette'" :format="'soc'" :buttonText="'Staroffice Palette'" @download-file="downloadFile" />
                                  </div>
                            </div>
                        </div>
                     </div>
                  </div>
            </div>
            
            <div class="mx-auto max-w-7xl px-6 lg:flex lg:px-8 opacity-100" :class="{ 'opacity-40': typedUrl === '' && isLoading === false }">
              <div class="lg:ml-96 lg:flex lg:w-full lg:justify-end lg:pl-32">
                <div class="mx-auto max-w-lg lg:mx-0 lg:w-0 lg:max-w-xl lg:flex-auto typography">
                  <div class="bg-gray-950">
                    <div class="mx-auto max-w-7xl">
                      <div class="sm:hidden">
                        <label for="tabs" class="sr-only">Select a tab</label>
                        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
                        <!-- <select @change="changePaletteView($event)" id="tabs" name="tabs" class="block w-full rounded-md border-none bg-gray-900 py-2 pl-3 pr-10 text-base text-white shadow-sm ring-1 ring-inset ring-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm">
                          <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }} </option>
                        </select> -->
                        <Listbox as="div">
                          <div class="relative mt-2">
                            <ListboxButton class="relative w-full cursor-pointer rounded-md bg-gray-800 py-1.5 pl-3 pr-10 text-left text-white shadow-sm ring-1 ring-inset ring-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                              <span v-if="tabs[0].current" class="block truncate">{{ tabs[0].name }}</span>
                              <span v-if="tabs[1].current" class="block truncate">{{ tabs[1].name }}</span>
                              <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                              </span>
                            </ListboxButton>

                            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                              <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ListboxOption as="template" v-for="tab in tabs" :key="tab.name" @click="changePaletteView(tab)">
                                  <li :class="[tab.current ? 'bg-indigo-600 text-white' : 'text-gray-300', 'relative cursor-pointer select-none py-2 pl-3 pr-9']" >
                                    <div class="flex gap-1">
                                    <span :class="[tab.current ? 'font-semibold' : 'font-normal', 'block truncate']">{{ tab.name }}</span>
                                    <span v-if="tabs[1] === tab && isLoading === false" :class="[tab.current ? 'bg-indigo-500 text-white rounded-full items-center inline-flex  px-1.5 py-0.5 text-xs font-medium' : 'inline-flex items-center rounded-full bg-gray-700 px-1.5 py-0.5 text-xs font-medium text-gray-400']">
                                      Colors: {{totalCodePaletteColors}}
                                    </span>
                                    <span v-if="tabs[0] === tab && isLoading === false" :class="[tab.current ? 'bg-indigo-500 text-white rounded-full items-center inline-flex  px-1.5 py-0.5 text-xs font-medium' : 'inline-flex items-center rounded-full bg-gray-700 px-1.5 py-0.5 text-xs font-medium text-gray-400']">
                                      Colors: {{totalVisualPaletteColors}}
                                    </span>
                                    </div>
                                    <span :class="[tab.current ? 'font-semibold' : 'font-normal', 'block truncate', 'text-xs', 'mt-1']">{{ tab.subName }}</span>
                                    

                                    <span v-if="tab.current" :class="[tab.current ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  </li>
                                </ListboxOption>
                              </ListboxOptions>
                            </transition>
                          </div>
                        </Listbox>
                      </div>
                      <div class="hidden sm:block">
                        <nav class="flex pt-4">
                          <ul role="list" class="flex min-w-full gap-x-6 text-sm font-semibold leading-6 text-gray-400 border-b border-gray-800">
                            <li v-for="tab in tabs" :key="tab.name" @click="changePaletteView(tab)" class="cursor-pointer pb-3 w-1/2 transition" :class="tab.current ? 'border-b-2 border-indigo-400 ' : ''">
                              <div :class="tab.current ? '' : 'hover:text-gray-300'" class="hover:text-gray-300 transition">
                                <h2 class="text-xl font-bold w-full" :class="tab.current ? 'text-indigo-400 ' : ''" >{{ tab.name }}</h2>
                                <h3 class="text-xs w-full" :class="tab.current ? 'text-gray-400' : ''">{{ tab.subName }}</h3>
                                <span v-if="tabs[1] === tab && isLoading === false" class="mt-3 inline-flex items-center rounded-full bg-gray-800 px-1.5 py-0.5 text-xs font-medium text-gray-400">
                                  Colors: {{totalCodePaletteColors}}
                                </span>
                                <span v-if="tabs[0] === tab && isLoading === false" class="mt-3 inline-flex items-center rounded-full bg-gray-800 px-1.5 py-0.5 text-xs font-medium text-gray-400">
                                  Colors: {{totalVisualPaletteColors}}
                                </span>
                                <span v-if="isLoading === true" class="mt-3 inline-flex items-center rounded-full bg-gray-800 px-1.5 py-0.5 text-xs font-medium text-gray-400">
                                  Colors: 
                                  <div v-if="isLoading" class="flex">
                                  <svg class="animate-spin ml-1 h-3 w-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                  </svg>
                                  </div>
                                </span>
                                
                              </div>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            

            <div v-if="totalVisualPaletteColors === 0 && tabs[0].current === true && isLoading == false" class="mx-auto max-w-7xl px-6 lg:flex lg:px-8 opacity-100" :class="{ 'opacity-40': typedUrl === '' && isLoading === false }">
                <div class="lg:ml-96 lg:flex lg:w-full lg:justify-end lg:pl-32">
                  <div class="mx-auto max-w-lg lg:mx-0 lg:w-0 lg:max-w-xl lg:flex-auto typography mt-8">
                      <h2 class="text-center text-white text-2xl font-bold mb-1">No Color Palettes Found</h2>
                      <h3 class="text-center text-base text-gray-400">If you think this is an error, please double-check the extraction settings or send us feedback on Discord.</h3>
                  </div>
                </div>
            </div>
            <div v-if="isLoading" class="mx-auto max-w-7xl px-6 lg:flex lg:px-8 opacity-100" :class="{ 'opacity-40': typedUrl === '' && isLoading === false }">
                <div class="lg:ml-96 lg:flex lg:w-full lg:justify-end lg:pl-32">
                  <div class="mx-auto max-w-lg lg:mx-0 lg:w-0 lg:max-w-xl lg:flex-auto typography mt-8 mb-32">
                    <Vue3Lottie 
                    v-if="Vue3Lottie"
                     :animationData="animationData"
                      :width="200"
                    />
                    <div class="text-lg text-center text-white font-bold">
                      Generating...
                    </div>
                    <div class="text-sm text-gray-400 text-center">
                      Please be patient. Our robots are working hard to extract every pixel.
                    </div>
                  </div>
                </div>
            </div>

            <div v-if="totalCodePaletteColors === 0 && tabs[1].current === true && isLoading == false" class="mx-auto max-w-7xl px-6 lg:flex lg:px-8 opacity-100" :class="{ 'opacity-40': typedUrl === '' && isLoading === false }">
                <div class="lg:ml-96 lg:flex lg:w-full lg:justify-end lg:pl-32">
                  <div class="mx-auto max-w-lg lg:mx-0 lg:w-0 lg:max-w-xl lg:flex-auto typography mt-8">
                      <h2 class="text-center text-white text-2xl font-bold mb-1">No Color Palettes Found</h2>
                      <h3 class="text-center text-base text-gray-400">If you think this is an error, please double-check the extraction settings or send us feedback on Discord.</h3>
                  </div>
                </div>
            </div>

                        <div v-if="Object.keys(response.visualPalette['Custom Colors']).length !== 0 && tabs[0].current == true && isLoading == false" class="mx-auto max-w-7xl px-6 lg:flex lg:px-8 opacity-100" :class="{ 'opacity-40': typedUrl === '' && isLoading === false }">
                          <div class="lg:ml-96 lg:flex lg:w-full lg:justify-end lg:pl-32">
                              <div class="mx-auto max-w-lg lg:mx-0 lg:w-0 lg:max-w-xl lg:flex-auto typography ">
                                <h2 class="text-white text-l font-bold mb-2">Custom Colors</h2>
                                <div class="grid sm:grid-cols-3 grid-cols-2 gap-4 ">
                                  <div v-for="(colorObj, index) in response.visualPalette['Custom Colors']" :key="colorObj.color" class="transition-all relative divide-y h-min overflow-hidden border-4 rounded-lg shadow transition border-gray-900" :class="{ 'outline-gray-700 outline outline-offset-0 outline-2 outline-dashed rounded-lg': isColorSelected('Custom Colors', colorObj, index) && enableEdit}">
                                    <img src="../assets/img/checkerboard_tile.svg " alt="" class="absolute">
                                    <div :style="{ backgroundColor: colorObj.color }" class="px-4 py-7 sm:p-7 h-3/6 relative -mt-1"></div>
                                    <img src="../assets/img/belly.svg" alt="belly" class="w-full relative -mb-0.5" :style="{ backgroundColor: colorObj.color, border: 0 }">
                                    <div class="h-full px-4 pb-4 pt-2 sm:px-6 bg-white border-white relative">
                                      <p class="text-xs font-normal text-gray-500">{{ colorObj.name }}</p>
                                      <p class="text-xs font-bold text-gray-600">{{ colorObj.color }}</p>
                                      <button
                                        @click.stop="copyToClipboard(colorObj.color)"
                                        type="button"
                                        class="transition-all opacity-60 hover:opacity-80 flex gap-1 items-center bg-gray-200 px-2 py-0.5 rounded-full mt-2"
                                        style="border-top: 0;"
                                      >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 10 10" fill="">
                                      <rect y="2.35276" width="7.64724" height="7.64724" rx="1.5"/>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.38144 1.20525C2.4768 1.18623 2.57543 1.17626 2.67638 1.17626H7.32362C8.15205 1.17626 8.82362 1.84783 8.82362 2.67626V7.3235C8.82362 7.4243 8.81368 7.52278 8.79472 7.61801C9.48191 7.481 9.99978 6.87448 9.99978 6.147V1.49976C9.99978 0.671333 9.32821 -0.000240326 8.49978 -0.000240326H3.85254C3.12491 -0.000240326 2.51829 0.517842 2.38144 1.20525Z"/>
                                      </svg>
                                      <p class="text-xs font-semibold text-black">Copy</p>
                                      </button>
                                    </div>
                                    

                                      <div class="flex absolute top-0 p-2 justify-between w-full border-transparent	">
                                    <button v-if="enableEdit" @click.stop="toggleSelection('Custom Colors', colorObj, index)" type="button" class="transition rounded-full hover:bg-gray-800 bg-gray-700 p-1 px-2 text-white flex items-center gap-2" style="border-top: 0;">
                                      <svg v-if="!isColorSelected('Custom Colors', colorObj, index)" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10" fill="none">
                                        <rect x="0.5" y="0.5" width="9" height="9" rx="1.5" fill="#626C7A" stroke="white"/>
                                        </svg>
                                      <svg v-if="isColorSelected('Custom Colors', colorObj, index)" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10" fill="none">
                                        <rect x="0.5" y="0.5" width="9" height="9" rx="1.5" fill="#626C7A" stroke="white"/>
                                        <path d="M4.81641 7.59855L4.8164 7.59857C4.77644 7.66162 4.72357 7.71478 4.66144 7.75404C4.59929 7.79331 4.52948 7.81767 4.45704 7.82521C4.3846 7.83275 4.31151 7.82326 4.24309 7.79749C4.17468 7.77172 4.11277 7.73037 4.06172 7.67661L4.06171 7.6766L2.30385 5.82472L2.30264 5.82344L2.30266 5.82342C2.21654 5.72606 2.17003 5.59777 2.17224 5.46567C2.17446 5.33357 2.22524 5.20704 2.31462 5.11288C2.40408 5.01864 2.52531 4.96416 2.65295 4.96178C2.7806 4.95941 2.90364 5.00936 2.99625 5.10028L2.99751 5.10152L2.99749 5.10154L4.33204 6.50748L6.93041 2.40115L6.93044 2.4011C7.00213 2.2881 7.11399 2.20898 7.24198 2.18214C7.37003 2.15529 7.50275 2.1832 7.61079 2.25919L4.81641 7.59855ZM4.81641 7.59855L7.74618 2.96883L7.70393 2.94209L4.81641 7.59855Z" fill="white" stroke="white" stroke-width="0.1"/>
                                        </svg>
                                        <p class="text-xs font-medium "> Select</p>
                                    </button>
                                    <button v-if="enableEdit" @click.stop="deleteColorVisual(index, 'Custom Colors')" type="button" class="transition rounded-full hover:bg-gray-800 bg-gray-700 p-1 text-white" style="border-top: 0;">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
                                        <path fill-rule="evenodd" d="M5 3.25V4H2.75a.75.75 0 0 0 0 1.5h.3l.815 8.15A1.5 1.5 0 0 0 5.357 15h5.285a1.5 1.5 0 0 0 1.493-1.35l.815-8.15h.3a.75.75 0 0 0 0-1.5H11v-.75A2.25 2.25 0 0 0 8.75 1h-1.5A2.25 2.25 0 0 0 5 3.25Zm2.25-.75a.75.75 0 0 0-.75.75V4h3v-.75a.75.75 0 0 0-.75-.75h-1.5ZM6.05 6a.75.75 0 0 1 .787.713l.275 5.5a.75.75 0 0 1-1.498.075l-.275-5.5A.75.75 0 0 1 6.05 6Zm3.9 0a.75.75 0 0 1 .712.787l-.275 5.5a.75.75 0 0 1-1.498-.075l.275-5.5a.75.75 0 0 1 .786-.711Z" clip-rule="evenodd" />
                                      </svg>
                                    </button>
                                  </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                        </div>
            
                <div v-if="Object.keys(response.visualPalette['Logo Colors']).length !== 0 && tabs[0].current == true && isLoading == false" class="mx-auto max-w-7xl px-6 lg:flex lg:px-8 opacity-100" :class="{ 'opacity-40': typedUrl === '' && isLoading === false }">
                          <div class="lg:ml-96 lg:flex lg:w-full lg:justify-end lg:pl-32">
                              <div class="mx-auto max-w-lg lg:mx-0 lg:w-0 lg:max-w-xl lg:flex-auto typography ">
                                <h2 class="text-white text-l font-bold mb-2">Logo Colors</h2>
                                <div class="grid sm:grid-cols-3 grid-cols-2 gap-4 ">
                                  <div v-for="(colorObj, index) in response.visualPalette['Logo Colors']" :key="colorObj.color" class="transition-all relative divide-y h-min overflow-hidden border-4 rounded-lg shadow transition border-gray-900" :class="{ 'outline-gray-700 outline outline-offset-0 outline-2 outline-dashed rounded-lg': isColorSelected('Logo Colors', colorObj, index) && enableEdit}">
                                    <img src="../assets/img/checkerboard_tile.svg " alt="" class="absolute">
                                    <div :style="{ backgroundColor: colorObj.color }" class="px-4 py-7 sm:p-7 h-3/6 relative -mt-1"></div>
                                    <img src="../assets/img/belly.svg" alt="belly" class="w-full relative -mb-0.5" :style="{ backgroundColor: colorObj.color, border: 0 }">
                                    <div class="h-full px-4 pb-4 pt-2 sm:px-6 bg-white border-white relative">
                                      <p class="text-xs font-normal text-gray-500">{{ colorObj.name }}</p>
                                      <p class="text-xs font-bold text-gray-600">{{ colorObj.color }}</p>
                                      <button
                                        @click.stop="copyToClipboard(colorObj.color)"
                                        type="button"
                                        class="transition-all opacity-60 hover:opacity-80 flex gap-1 items-center bg-gray-200 px-2 py-0.5 rounded-full mt-2"
                                        style="border-top: 0;"
                                      >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 10 10" fill="">
                                      <rect y="2.35276" width="7.64724" height="7.64724" rx="1.5"/>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.38144 1.20525C2.4768 1.18623 2.57543 1.17626 2.67638 1.17626H7.32362C8.15205 1.17626 8.82362 1.84783 8.82362 2.67626V7.3235C8.82362 7.4243 8.81368 7.52278 8.79472 7.61801C9.48191 7.481 9.99978 6.87448 9.99978 6.147V1.49976C9.99978 0.671333 9.32821 -0.000240326 8.49978 -0.000240326H3.85254C3.12491 -0.000240326 2.51829 0.517842 2.38144 1.20525Z"/>
                                      </svg>
                                      <p class="text-xs font-semibold text-black">Copy</p>
                                      </button>
                                    </div>
                                    

                                      <div class="flex absolute top-0 p-2 justify-between w-full border-transparent	">
                                    <button v-if="enableEdit" @click.stop="toggleSelection('Logo Colors', colorObj, index)" type="button" class="transition rounded-full hover:bg-gray-800 bg-gray-700 p-1 px-2 text-white flex items-center gap-2" style="border-top: 0;">
                                      <svg v-if="!isColorSelected('Logo Colors', colorObj, index)" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10" fill="none">
                                        <rect x="0.5" y="0.5" width="9" height="9" rx="1.5" fill="#626C7A" stroke="white"/>
                                        </svg>
                                      <svg v-if="isColorSelected('Logo Colors', colorObj, index)" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10" fill="none">
                                        <rect x="0.5" y="0.5" width="9" height="9" rx="1.5" fill="#626C7A" stroke="white"/>
                                        <path d="M4.81641 7.59855L4.8164 7.59857C4.77644 7.66162 4.72357 7.71478 4.66144 7.75404C4.59929 7.79331 4.52948 7.81767 4.45704 7.82521C4.3846 7.83275 4.31151 7.82326 4.24309 7.79749C4.17468 7.77172 4.11277 7.73037 4.06172 7.67661L4.06171 7.6766L2.30385 5.82472L2.30264 5.82344L2.30266 5.82342C2.21654 5.72606 2.17003 5.59777 2.17224 5.46567C2.17446 5.33357 2.22524 5.20704 2.31462 5.11288C2.40408 5.01864 2.52531 4.96416 2.65295 4.96178C2.7806 4.95941 2.90364 5.00936 2.99625 5.10028L2.99751 5.10152L2.99749 5.10154L4.33204 6.50748L6.93041 2.40115L6.93044 2.4011C7.00213 2.2881 7.11399 2.20898 7.24198 2.18214C7.37003 2.15529 7.50275 2.1832 7.61079 2.25919L4.81641 7.59855ZM4.81641 7.59855L7.74618 2.96883L7.70393 2.94209L4.81641 7.59855Z" fill="white" stroke="white" stroke-width="0.1"/>
                                        </svg>
                                        <p class="text-xs font-medium "> Select</p>
                                    </button>
                                    <button v-if="enableEdit" @click.stop="deleteColorVisual(index, 'Logo Colors')" type="button" class="transition rounded-full hover:bg-gray-800 bg-gray-700 p-1 text-white" style="border-top: 0;">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
                                        <path fill-rule="evenodd" d="M5 3.25V4H2.75a.75.75 0 0 0 0 1.5h.3l.815 8.15A1.5 1.5 0 0 0 5.357 15h5.285a1.5 1.5 0 0 0 1.493-1.35l.815-8.15h.3a.75.75 0 0 0 0-1.5H11v-.75A2.25 2.25 0 0 0 8.75 1h-1.5A2.25 2.25 0 0 0 5 3.25Zm2.25-.75a.75.75 0 0 0-.75.75V4h3v-.75a.75.75 0 0 0-.75-.75h-1.5ZM6.05 6a.75.75 0 0 1 .787.713l.275 5.5a.75.75 0 0 1-1.498.075l-.275-5.5A.75.75 0 0 1 6.05 6Zm3.9 0a.75.75 0 0 1 .712.787l-.275 5.5a.75.75 0 0 1-1.498-.075l.275-5.5a.75.75 0 0 1 .786-.711Z" clip-rule="evenodd" />
                                      </svg>
                                    </button>
                                  </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                        </div>
                      <div v-if="Object.keys(response.visualPalette['Homepage Colors']).length !== 0 && tabs[0].current == true && isLoading == false" class="mx-auto max-w-7xl px-6 lg:flex lg:px-8 opacity-100" :class="{ 'opacity-40': typedUrl === '' && isLoading === false }">
                        <div class="lg:ml-96 lg:flex lg:w-full lg:justify-end lg:pl-32">
                            <div class="mx-auto max-w-lg lg:mx-0 lg:w-0 lg:max-w-xl lg:flex-auto typography ">
                              <h2 class="text-white text-l font-bold mb-2">Homepage Colors</h2>
                              <div class="grid sm:grid-cols-3 grid-cols-2 gap-4" >
                                <div v-for="(colorObj, index) in response.visualPalette['Homepage Colors']" :key="colorObj.color" class="transition-all relative divide-y h-min overflow-hidden border-4 bg-white rounded-lg shadow transition border-gray-900" :class="{ 'outline-gray-700 outline outline-offset-0 outline-2 outline-dashed rounded-lg': isColorSelected('Homepage Colors', colorObj, index) && enableEdit}">
                                  <img src="../assets/img/checkerboard_tile.svg " alt="" class="absolute">
                                  <div :style="{ backgroundColor: colorObj.color }" class="px-4 py-7 sm:p-7 h-3/6 relative -mt-1"></div>
                                  <img src="../assets/img/belly.svg" alt="belly" class="w-full relative -mb-0.5" :style="{ backgroundColor: colorObj.color, border: 0 }">
                                  <div class="h-full px-4 pb-4 pt-2 sm:px-6 bg-white border-white relative">
                                      <p class="text-xs font-normal text-gray-500">{{ colorObj.name }}</p>
                                      <p class="text-xs font-bold text-gray-600">{{ colorObj.color }}</p>
                                      <button
                                        @click.stop="copyToClipboard(colorObj.color)"
                                        type="button"
                                        class="transition-all opacity-60 hover:opacity-80 flex gap-1 items-center bg-gray-200 px-2 py-0.5 rounded-full mt-2"
                                        style="border-top: 0;"
                                      >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 10 10" fill="">
                                      <rect y="2.35276" width="7.64724" height="7.64724" rx="1.5"/>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.38144 1.20525C2.4768 1.18623 2.57543 1.17626 2.67638 1.17626H7.32362C8.15205 1.17626 8.82362 1.84783 8.82362 2.67626V7.3235C8.82362 7.4243 8.81368 7.52278 8.79472 7.61801C9.48191 7.481 9.99978 6.87448 9.99978 6.147V1.49976C9.99978 0.671333 9.32821 -0.000240326 8.49978 -0.000240326H3.85254C3.12491 -0.000240326 2.51829 0.517842 2.38144 1.20525Z"/>
                                      </svg>
                                      <p class="text-xs font-semibold text-black">Copy</p>
                                      </button>
                                    </div>
                                    <div class="flex absolute top-0 p-2 justify-between w-full border-transparent	">
                                    <button v-if="enableEdit" @click.stop="toggleSelection('Homepage Colors', colorObj, index)" type="button" class="transition rounded-full hover:bg-gray-800 bg-gray-700 p-1 px-2 text-white flex items-center gap-2" style="border-top: 0;">
                                      <svg v-if="!isColorSelected('Homepage Colors', colorObj, index)" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10" fill="none">
                                        <rect x="0.5" y="0.5" width="9" height="9" rx="1.5" fill="#626C7A" stroke="white"/>
                                        </svg>
                                      <svg v-if="isColorSelected('Homepage Colors', colorObj, index)" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10" fill="none">
                                        <rect x="0.5" y="0.5" width="9" height="9" rx="1.5" fill="#626C7A" stroke="white"/>
                                        <path d="M4.81641 7.59855L4.8164 7.59857C4.77644 7.66162 4.72357 7.71478 4.66144 7.75404C4.59929 7.79331 4.52948 7.81767 4.45704 7.82521C4.3846 7.83275 4.31151 7.82326 4.24309 7.79749C4.17468 7.77172 4.11277 7.73037 4.06172 7.67661L4.06171 7.6766L2.30385 5.82472L2.30264 5.82344L2.30266 5.82342C2.21654 5.72606 2.17003 5.59777 2.17224 5.46567C2.17446 5.33357 2.22524 5.20704 2.31462 5.11288C2.40408 5.01864 2.52531 4.96416 2.65295 4.96178C2.7806 4.95941 2.90364 5.00936 2.99625 5.10028L2.99751 5.10152L2.99749 5.10154L4.33204 6.50748L6.93041 2.40115L6.93044 2.4011C7.00213 2.2881 7.11399 2.20898 7.24198 2.18214C7.37003 2.15529 7.50275 2.1832 7.61079 2.25919L4.81641 7.59855ZM4.81641 7.59855L7.74618 2.96883L7.70393 2.94209L4.81641 7.59855Z" fill="white" stroke="white" stroke-width="0.1"/>
                                        </svg>
                                        <p class="text-xs font-medium "> Select</p>
                                    </button>
                                    <button v-if="enableEdit" @click.stop="deleteColorVisual(index, 'Homepage Colors')" type="button" class="transition rounded-full hover:bg-gray-800 bg-gray-700 p-1 text-white" style="border-top: 0;">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
                                        <path fill-rule="evenodd" d="M5 3.25V4H2.75a.75.75 0 0 0 0 1.5h.3l.815 8.15A1.5 1.5 0 0 0 5.357 15h5.285a1.5 1.5 0 0 0 1.493-1.35l.815-8.15h.3a.75.75 0 0 0 0-1.5H11v-.75A2.25 2.25 0 0 0 8.75 1h-1.5A2.25 2.25 0 0 0 5 3.25Zm2.25-.75a.75.75 0 0 0-.75.75V4h3v-.75a.75.75 0 0 0-.75-.75h-1.5ZM6.05 6a.75.75 0 0 1 .787.713l.275 5.5a.75.75 0 0 1-1.498.075l-.275-5.5A.75.75 0 0 1 6.05 6Zm3.9 0a.75.75 0 0 1 .712.787l-.275 5.5a.75.75 0 0 1-1.498-.075l.275-5.5a.75.75 0 0 1 .786-.711Z" clip-rule="evenodd" />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                      
                      <div v-if="Object.keys(response.nearestColors).length !== 0 && tabs[1].current === true && isLoading == false" v-for="(colors, group) in response.nearestColors" :key="group">
                        <div v-if="Object.keys(response.nearestColors[group]).length !== 0"  class="mx-auto max-w-7xl px-6 lg:flex lg:px-8opacity-100" :class="{ 'opacity-40': typedUrl === '' && isLoading === false }">
                          <div class="lg:ml-96 lg:flex lg:w-full lg:justify-end lg:pl-32">
                            <div class="mx-auto max-w-lg lg:mx-0 lg:w-0 lg:max-w-xl lg:flex-auto typography">
                              <h2 class="text-white text-l font-bold mb-2">{{ group }}</h2>
                              <div class="grid sm:grid-cols-3 grid-cols-2 gap-4">
                                <div v-for="(colorObj, index) in colors" :key="colorObj.color" class="transition-all divide-y h-min overflow-hidden border-4 bg-white rounded-lg shadow relative transition border-gray-900" :class="{ 'outline-gray-700 outline outline-offset-0 outline-2 outline-dashed rounded-lg': isColorSelected(group, colorObj, index) && enableEdit }">
                                  <img src="../assets/img/checkerboard_tile.svg" alt="" class="absolute">
                                  <div :style="{ backgroundColor: colorObj.color, border: 0 }" class="px-4 py-7 sm:p-7 relative -mt-1 "></div>
                                  <img src="../assets/img/belly.svg" alt="belly" class="w-full relative -mb-0.5" :style="{ backgroundColor: colorObj.color, border: 0 }">
                                  <div class="h-full px-4 pb-4 pt-2 sm:px-6 bg-white border-white relative">
                                      <p class="text-xs font-normal text-gray-500">{{ colorObj.name }}</p>
                                      <p class="text-xs font-bold text-gray-600">{{ colorObj.color }}</p>
                                      <button
                                        @click.stop="copyToClipboard(colorObj.color)"
                                        type="button"
                                        class="transition-all opacity-60 hover:opacity-80 flex gap-1 items-center bg-gray-200 px-2 py-0.5 rounded-full mt-2"
                                        style="border-top: 0;"
                                      >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 10 10" fill="">
                                      <rect y="2.35276" width="7.64724" height="7.64724" rx="1.5"/>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.38144 1.20525C2.4768 1.18623 2.57543 1.17626 2.67638 1.17626H7.32362C8.15205 1.17626 8.82362 1.84783 8.82362 2.67626V7.3235C8.82362 7.4243 8.81368 7.52278 8.79472 7.61801C9.48191 7.481 9.99978 6.87448 9.99978 6.147V1.49976C9.99978 0.671333 9.32821 -0.000240326 8.49978 -0.000240326H3.85254C3.12491 -0.000240326 2.51829 0.517842 2.38144 1.20525Z"/>
                                      </svg>
                                      <p class="text-xs font-semibold text-black">Copy</p>
                                      </button>
                                    </div>
                                  <div class="flex absolute top-0 p-2 justify-between w-full border-transparent	">
                                    <button v-if="enableEdit" @click.stop="toggleSelection(group, colorObj, index)" type="button" class="transition rounded-full hover:bg-gray-800 bg-gray-700 p-1 px-2 text-white flex items-center gap-2" style="border-top: 0;">
                                      <svg v-if="!isColorSelected(group, colorObj, index)" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10" fill="none">
                                        <rect x="0.5" y="0.5" width="9" height="9" rx="1.5" fill="#626C7A" stroke="white"/>
                                        </svg>
                                      <svg v-if="isColorSelected(group, colorObj, index)" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10" fill="none">
                                        <rect x="0.5" y="0.5" width="9" height="9" rx="1.5" fill="#626C7A" stroke="white"/>
                                        <path d="M4.81641 7.59855L4.8164 7.59857C4.77644 7.66162 4.72357 7.71478 4.66144 7.75404C4.59929 7.79331 4.52948 7.81767 4.45704 7.82521C4.3846 7.83275 4.31151 7.82326 4.24309 7.79749C4.17468 7.77172 4.11277 7.73037 4.06172 7.67661L4.06171 7.6766L2.30385 5.82472L2.30264 5.82344L2.30266 5.82342C2.21654 5.72606 2.17003 5.59777 2.17224 5.46567C2.17446 5.33357 2.22524 5.20704 2.31462 5.11288C2.40408 5.01864 2.52531 4.96416 2.65295 4.96178C2.7806 4.95941 2.90364 5.00936 2.99625 5.10028L2.99751 5.10152L2.99749 5.10154L4.33204 6.50748L6.93041 2.40115L6.93044 2.4011C7.00213 2.2881 7.11399 2.20898 7.24198 2.18214C7.37003 2.15529 7.50275 2.1832 7.61079 2.25919L4.81641 7.59855ZM4.81641 7.59855L7.74618 2.96883L7.70393 2.94209L4.81641 7.59855Z" fill="white" stroke="white" stroke-width="0.1"/>
                                        </svg>
                                        <p class="text-xs font-medium "> Select</p>
                                    </button>
                                    <button v-if="enableEdit" @click.stop="deleteColorNearest(group, index)" type="button" class="transition rounded-full hover:bg-gray-800 bg-gray-700 p-1 text-white" style="border-top: 0;">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
                                        <path fill-rule="evenodd" d="M5 3.25V4H2.75a.75.75 0 0 0 0 1.5h.3l.815 8.15A1.5 1.5 0 0 0 5.357 15h5.285a1.5 1.5 0 0 0 1.493-1.35l.815-8.15h.3a.75.75 0 0 0 0-1.5H11v-.75A2.25 2.25 0 0 0 8.75 1h-1.5A2.25 2.25 0 0 0 5 3.25Zm2.25-.75a.75.75 0 0 0-.75.75V4h3v-.75a.75.75 0 0 0-.75-.75h-1.5ZM6.05 6a.75.75 0 0 1 .787.713l.275 5.5a.75.75 0 0 1-1.498.075l-.275-5.5A.75.75 0 0 1 6.05 6Zm3.9 0a.75.75 0 0 1 .712.787l-.275 5.5a.75.75 0 0 1-1.498-.075l.275-5.5a.75.75 0 0 1 .786-.711Z" clip-rule="evenodd" />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
                      <div class="lg:ml-96 lg:flex lg:w-full lg:justify-end lg:pl-32">
                        <div class="mx-auto max-w-lg lg:mx-0 lg:w-0 lg:max-w-xl lg:flex-auto typography ">     
                          <div class="relative divide-y h-min overflow-hidden border-2 bg-gray-900 rounded-lg shadow transition border-gray-800 sm:flex gap-6 p-5 ">
                            <div class="border-gray-900 mb-3">
                            <h1 class="text-2xl font-bold text-white border-gray-900">Do you want API access?</h1>
                            <p class="pt-3 text-sm text-gray-400 border-gray-800">Access our API to integrate color and URL extraction into your app:</p>
                            <div class="my-3 mb-6 border-gray-900">
                              <div class="flex gap-1 border-gray-900 items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="gray" class="size-4">
                                  <path fill-rule="evenodd" d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z" clip-rule="evenodd" />
                                </svg>
                                <p class="text-sm text-gray-400">Page Screenshot & Favicon</p>
                              </div>
                              <div class="flex gap-1 border-gray-900 items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="gray" class="size-4">
                                  <path fill-rule="evenodd" d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z" clip-rule="evenodd" />
                                </svg>
                                <p class="text-sm text-gray-400">Color Extraction & Grouping</p>
                              </div>
                              <div class="flex gap-1 border-gray-800 items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="gray" class="size-4">
                                  <path fill-rule="evenodd" d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z" clip-rule="evenodd" />
                                </svg>
                                <p class="text-sm text-gray-400">Website & Company Information</p>
                              </div>
                            </div>
                            <a target="_blank" class="border-gray-800 transition rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-gray-500 cursor-pointer" @click="apiClicked()">
                              Sign up for free
                            </a>
                          </div>
                          <img src="../assets/img/code.svg" alt="" class="sm:-mb-20 -mb-28 sm:mt-4 mt-8 order-first border-gray-900 mx-auto -rotate-[4deg] w-1/2 px-3">
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
                      <div class="lg:ml-96 lg:flex lg:w-full lg:justify-end lg:pl-32">
                        <div class="mx-auto max-w-lg lg:mx-0 lg:w-0 lg:max-w-xl lg:flex-auto typography "> 
                          <div class="text-sm leading-6 text-white sm:flex items-center ">
                            <div class="sm:flex gap-2 items-center hidden sm:flex	w-max mr-auto">
                              <p class="">        
                              Found bugs or have recomendations?</p>
                            </div>
                            <div class="flex gap-2">
                              <a type="button" class="transition w-max sm:ml-1 gap-2 justify-center content-center flex rounded bg-white/10 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-white/20" href="https://discord.gg/CjpUF6nJyG" target="_blank" @click="discordClick()">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15px" height="15px" viewBox="0 -28.5 256 256" version="1.1" preserveAspectRatio="xMidYMid">
                                      <g>
                                          <path d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z" fill="#78869c" fill-rule="nonzero">

                                  </path>
                                      </g>
                                  </svg>
                                Discuss on Discord
                              </a>
                                <a type="button" class="transition w-max sm:ml-1 gap-2 justify-center content-center flex rounded bg-white/10 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-white/20 cursor-pointer"  id="feedback-button" @click="feedbackClick()">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
                                    <path fill-rule="evenodd" d="M1 8.74c0 .983.713 1.825 1.69 1.943.904.108 1.817.19 2.737.243.363.02.688.231.85.556l1.052 2.103a.75.75 0 0 0 1.342 0l1.052-2.103c.162-.325.487-.535.85-.556.92-.053 1.833-.134 2.738-.243.976-.118 1.689-.96 1.689-1.942V4.259c0-.982-.713-1.824-1.69-1.942a44.45 44.45 0 0 0-10.62 0C1.712 2.435 1 3.277 1 4.26v4.482Zm3-3.49a.75.75 0 0 1 .75-.75h6.5a.75.75 0 0 1 0 1.5h-6.5A.75.75 0 0 1 4 5.25ZM4.75 7a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5h-2.5Z" clip-rule="evenodd" fill="#78869c" />
                                  </svg>
                                Send Feedback
                                </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    
        </div>
        </div>
      </NuxtLayout>
</template>

<script setup>
import { ref, onMounted, computed, reactive, onBeforeUnmount, nextTick, onErrorCaptured   } from 'vue'
import { Switch, SwitchGroup, SwitchLabel, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption  } from '@headlessui/vue'
import { PlusIcon, MinusIcon, CheckIcon, ChevronUpDownIcon, CheckCircleIcon } from '@heroicons/vue/20/solid'
import animationData from '../assets/img/animation.json';
import { response } from '../assets/js/colorData.js'; 
import BackgroundTile from '@/components/BackroundTile.vue';
import SwitchComponent from '~/components/SwitchComponent.vue';
import DownloadButton from '../components/DownloadButton.vue';
import { useRoute, useRouter, useHead } from '#app';
import { XMarkIcon } from '@heroicons/vue/20/solid'
import * as Sentry from '@sentry/vue';
import ColorCard from '~/components/ColorCard.vue';


useHead({
  title: 'Extract Color Palettes from Any Website - Free Color Palette Generator',
  meta: [
    { name: "description", content: "Generate color palettes instantly from any website effortlessly. Enter a URL to explore color schemes and combinations directly from the site's design. No sign-up required. Try it now!"},
  ],
  htmlAttrs: {
      lang: 'en'
    }
})

useSeoMeta({
  description: 'Generate color palettes instantly from any website effortlessly. Enter a URL to explore color schemes and combinations directly from the site\'s design. No sign-up required. Try it now!',
  ogTitle: 'Extract Color Palettes from Any Website - Free Color Palette Generator',
  ogDescription: 'Generate color palettes instantly from any website effortlessly. No sign-up required. Try it now!',
  ogImage: '/og-image.png',
  ogType: 'website',
  ogUrl: 'https://colorize.design',
})



let route = useRoute();
let router = useRouter();
let queryParam = ref(route.query);

let Vue3Lottie = ref(null);
let settingToggle = ref(false)
let urlData = ref("")
let typedUrl = ref("")
let hasError = ref(false)
let hasWarning = ref(false)
let hasSuccess = ref(false)
let errorMsg = ref("")
let isLoading = ref(false)
let isDropdownOpen = ref(false)
let dropdown = ref(null);
let dropdownButton = ref(null);
let backgroundTransparent = ref('');
let faviconError = ref(false);  
let scrollElement = ref(null);
let showNotification = ref(false)
let showNotificationColor = ref(false)
let showRibbon = ref(true)
let showFilter = ref(false)
let enableEdit = ref(false)
let screenshotLoaded = ref(false)
let screenshotPromtShow = ref(true)
let screenshotAnimation = ref(false)
let isMobile = ref(false)
let showNotificationColorAdded = ref(false)
let title = ref('Example Palette');
const editMode = ref(false);
const inputField = ref(null);
const canvas = ref(null);
const image = ref(null);
const selectedColor = ref('#ffffff');
const showMagnifier = ref(false);
const magnifierX = ref(0);
const magnifierY = ref(0);
const imageSrc = ref(null);

let token = ref(null)
const turnstile = ref()

onErrorCaptured((error) => {
  if (error.toString().includes('TurnstileError')) {
    console.log("asdasds")
    hasError.value=true
    errorMsg.value = 'CAPTCHA verification encountered an issue. Please refresh the page and try again.';
    
  }
});
  

let mailingLists = ref([
  { id: 1, title: 'Logo', description: "Colors from the site's favicon", checked: true, sliderCapable: true, currentVal: 6},
  { id: 2, title: 'Jpg Screenshot', description: 'Colors from homepage', checked: true, sliderCapable: true, currentVal: 12},
  { id: 3, title: 'Html', description: "Colors from the site's HTML", checked: true, sliderCapable: false},
  { id: 4, title: 'Css', description: 'Colors from all CSS files', checked: true, sliderCapable: false},
  { id: 5, title: 'Js', description: 'Colors from all JS files', checked: false, sliderCapable: false},
])

let tabs = ref([
  { name: 'Visual Palette Extraction', subName: "Colors are extracted from the site's visual layout taking a JPG snapshot.", href: '#', current: true },
  { name: 'Code Palette Extraction', subName: "Colors are extracted from the site's HTML, CSS, and JS files.", href: '#', current: false },
])

// Activate edit mode and move caret to the end
const activateEditMode = () => {
  editMode.value = true;
  nextTick(() => {
    if (inputField.value) {
      const length = inputField.value.value.length;
      inputField.value.focus();
      inputField.value.setSelectionRange(length, length);
    }
  });
};

// Watch editMode and focus input when active
watch(editMode, (newVal) => {
  if (newVal) {
    nextTick(() => inputField.value?.focus());
  }
});

const getColorAt = (x, y) => {
  if (!canvas.value) return '#ffffff'; // Ensure canvas exists
  const ctx = canvas.value.getContext('2d');
  if (!ctx) return '#ffffff'; // Ensure context exists
  const pixel = ctx.getImageData(x, y, 1, 1).data;
  return `rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`;
};

let touchTimeout;
let samplingEnabled = false; // Flag to enable color sampling after delay

const handleMouseMove = (event) => {
  if (!canvas.value || !image.value || screenshotAnimation.value) return;
  if (samplingEnabled) return; // Ignore if sampling is disabled

  screenshotPromtShow = false;

  const rect = image.value.getBoundingClientRect();
  const scaleX = image.value.naturalWidth / rect.width;
  const scaleY = image.value.naturalHeight / rect.height;

  const x = Math.floor((event.clientX - rect.left) * scaleX);
  const y = Math.floor((event.clientY - rect.top) * scaleY);

  selectedColor.value = getColorAt(x, y);
  magnifierX.value = event.clientX - rect.left + 15;
  magnifierY.value = event.clientY - rect.top + 15;
  showMagnifier.value = true;
};

const handleTouchStart = (event) => {
  samplingEnabled = false; // Disable sampling initially
  touchTimeout = setTimeout(() => {
    samplingEnabled = true; // Enable color sampling after 2 seconds
    handleTouchMove(event);
  }, 2000); // 2-second long-press delay
};

const handleTouchMove = (event) => {
  if (!samplingEnabled || !canvas.value || !image.value || screenshotAnimation.value) return;

  event.preventDefault();
  screenshotPromtShow = false;

  const touch = event.touches[0];
  const rect = image.value.getBoundingClientRect();
  const scaleX = image.value.naturalWidth / rect.width;
  const scaleY = image.value.naturalHeight / rect.height;

  const x = Math.floor((touch.clientX - rect.left) * scaleX);
  const y = Math.floor((touch.clientY - rect.top) * scaleY);

  selectedColor.value = getColorAt(x, y);
  magnifierX.value = touch.clientX - rect.left + 15;
  magnifierY.value = touch.clientY - rect.top - 100;
  showMagnifier.value = true;
};

const handleTouchEnd = () => {
  clearTimeout(touchTimeout);
  if (samplingEnabled && showMagnifier.value) { // Ensure sampling was enabled before selecting
    selectColor();
  }
  samplingEnabled = false; // Reset sampling flag
  showMagnifier.value = false;
  screenshotPromtShow = true;
  hideMagnifier();
};

const handleClick = () => {
  selectColor(); // Allow clicks normally on non-touch devices
};

const hideMagnifier = () => {
  showMagnifier.value = false;
  screenshotPromtShow = true;
};

const selectColor = () => {
  showNotificationColorAdded.value = true;
  customColorClick();
  if (scrollElement.value) {
    scrollElement.value.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  tabs.value.forEach(tab => {
      tab.current = tab.name === 'Visual Palette Extraction';
    });
  
    const customColor =[ {
        color: selectedColor.value,
        name: `custom`
      }
    ];
    response.visualPalette["Custom Colors"].push(...customColor);
};


const setupCanvas = async () => {
  await nextTick(); // Ensure the DOM updates before accessing elements

  if (!image.value || !canvas.value) return;

  const img = image.value;
  img.crossOrigin = "anonymous"; // Avoid CORS issues

  img.onload = () => {
    const ctx = canvas.value.getContext('2d');
    if (!ctx) {
      console.error('Canvas context is null');
      return;
    }

    canvas.value.width = img.naturalWidth;
    canvas.value.height = img.naturalHeight;

    ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
  };

  if (img.complete) {
    img.onload();
  }
};

// Call this function when the image is downloaded and assigned
const setDownloadedImage = (url) => {
  imageSrc.value = url;
};

// Watch for when the image is set and then initialize the canvas
watch(imageSrc, (newSrc) => {
  if (newSrc) {
    setupCanvas();
  }
});


watch(token, (newVal, oldVal) => {
  if (newVal && newVal !== oldVal) {
    if(queryParam.value.url != null)
    {
      // Parse the URL
      const urlObj = new URL(queryParam.value.url);

      // Get the 'url' parameter
      const extractedUrl = urlObj.searchParams.get("url");
      console.log("Fetched Data")
      fetchData(queryParam.value.url)
      urlData.value = extractedUrl
    }
  }
  if(hasWarning.value===true)
  {
    getUrl()
  }
},{ once: true })

onMounted(async () => {

  
isMobile = computed(() => /Mobi|Android/i.test(navigator.userAgent));

    if(queryParam.value.url != null)
    {
      isLoading.value = true;
      screenshotAnimation.value = true;

      // Start the timeout timer (30 seconds)
      const timeoutId = setTimeout(() => {
        // If this callback runs, the operation took too long
        if(typedUrl===''){
          hasError.value = true;
          errorMsg.value = "Request timed out. Please try again.";
          isLoading.value = false;
          screenshotAnimation.value = false;
        }

      }, 30000);
    }
    
  const feedback = Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "dark",
      isEmailRequired: true,
      buttonLabel: "Feedback",
      submitButtonLabel: "Send Feedback",
      formTitle: "Send Feedback",
      triggerLabel: "Send Feedback",
      showBranding: false,
      autoInject: false,
      messagePlaceholder: "Found bugs? Do you have suggestions?",
      themeDark: {
        background: "#030712",
        outline: "none",
        fontFamily: "Inter",
        
      },
  });

  feedback.attachTo(document.querySelector('#feedback-button'), {
  });

  const module = await import('vue3-lottie');
  Vue3Lottie.value = module.Vue3Lottie;
  document.addEventListener('click', handleClickOutside);

});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

let selectedColors = reactive({
  visualPalette: {
    'Logo Colors': [],
    'Homepage Colors': [],
    'Custom Colors': []
  },
  nearestColors: {}
})

const toggleSelection = (category, colorObj, index) => {
  if(enableEdit.value === true){
    showFilter.value = true
  let targetCategory

  if (category === 'Logo Colors' || category === 'Homepage Colors' || category === 'Custom Colors') {
    targetCategory = selectedColors.visualPalette[category]
  } else {
    if (!selectedColors.nearestColors[category]) {
      selectedColors.nearestColors[category] = []
    }
    targetCategory = selectedColors.nearestColors[category]
  }

  const existingIndex = targetCategory.findIndex(
    selected => selected.color === colorObj.color && selected.index === index
  )

  if (existingIndex === -1) {
    targetCategory.push({ ...colorObj, index })
  } else {
    targetCategory.splice(existingIndex, 1)
  }

  // Clean up empty categories in nearestColors
  if (category !== 'Logo Colors' && category !== 'Homepage Colors' && category !== 'Custom Colors') {
    if (targetCategory.length === 0) {
      delete selectedColors.nearestColors[category]
    }
  }
  }
}

let customColorClick = () => {
  try{
    window.clarity("event", "Custom Color Add")
  } catch{
    
  }
}

let nestorasClick = () => {
  try{
    window.clarity("event", "nestoras.design Clicked")
  } catch{
    
  }
}

let editToggle = () => {
  enableEdit.value = !enableEdit.value
  try{
    window.clarity("event", "Edit Toggle")
  } catch{
    
  }
}

let apiRibbonClicked = () => {
  router.push('/login')
  try{
    window.clarity("event", "Signup API Ribbon Clicked")

  } catch{
    
  }
}

let apiClicked = () => {
  router.push('/login')
  try{
    window.clarity("event", "Signup API Clicked")

  } catch{
    
  }
}

let discordClick = () => {
  try{
    window.clarity("event", "Discord Click")
  } catch{
    
  }
}

let feedbackClick = () => {
  try{
    window.clarity("event", "Feedback Click")
  } catch{
    
  }
}

const isColorSelected = (category, colorObj, index) => {
  if (category === 'Logo Colors' || category === 'Homepage Colors' || category === 'Custom Colors') {
    return checkVisualPaletteSelection(category, colorObj, index);
  } else {
    return checkNearestColorsSelection(category, colorObj, index);
  }
}

const checkVisualPaletteSelection = (category, colorObj, index) => {
  const selectedArray = selectedColors.visualPalette[category];
  if (!selectedArray || selectedArray.length === 0) return false;

  return selectedArray.some(selected => 
    selected.color === colorObj.color && 
    selected.name === colorObj.name && 
    selected.index === index
  );
}

const checkNearestColorsSelection = (category, colorObj, index) => {
  const selectedArray = selectedColors.nearestColors[category];
  if (!selectedArray || selectedArray.length === 0) return false;

  return selectedArray.some(selected => 
    selected.color === colorObj.color && 
    selected.name === colorObj.name && 
    selected.group === colorObj.group &&
    selected.index === index
  );
}

function mergePalettes() {
  try{
    response.visualPalette['Logo Colors'] = [];
    response.visualPalette['Homepage Colors'] = [];
    response.visualPalette['Custom Colors'] = [];
    response.nearestColors = {};
    Object.assign(response.nearestColors, selectedColors.nearestColors);
    Object.assign(response.visualPalette, selectedColors.visualPalette);
  }
  finally {
    resetFiltering()
  }
}

function resetFiltering(){
  showFilter.value = false;
  selectedColors.visualPalette['Logo Colors'] = [];
  selectedColors.visualPalette['Homepage Colors'] = [];
  selectedColors.visualPalette['Custom Colors'] = [];
  selectedColors.nearestColors = {};
}

function copyToClipboard(color) {
  try{
    window.clarity("event", "Color Copied")
  } catch{
    
  }
  navigator.clipboard.writeText(color).then(() => {
    showNotificationColor.value = true
  }).catch(err => {
  })
}

function toggleCheck(mailingList) {
  try{
    window.clarity("event", "Toggle Setting Options ("+mailingList.title+")")
  } catch{
    
  }
  mailingList.checked = !mailingList.checked;
}

const changePaletteView = (givenTab) => {
      try{
        window.clarity("event", "Change Palette View ("+givenTab.target.value+")")
      } catch{
      }
      if (givenTab.target === undefined) {
        tabs.value.forEach(tab => {
          tab.current = (tab === givenTab);
          if(tab === givenTab)
          {
            try{
              window.clarity("event", "Change Palette View ("+tab.name+")")
            } catch{
            }
          }
          
        });
      } else {
        tabs.value.forEach(tab => {
          tab.current = (tab.name === givenTab.target.value);
          if(tab.name === givenTab.target.value){
            try{
              window.clarity("event", "Change Palette View ("+tab.name+")")
            } catch{
            }
          }
        });
      }
};

const openNotification = () => {
  try {
    const currentUrl = `${window.location.origin}${route.fullPath}`;
    navigator.clipboard.writeText(currentUrl).then(() => {
    }).catch(err => {
    });
    window.clarity("event", "Share Clicked")
  } catch (error) {
  }
  showNotification.value = true
}

let baseUrl = 'https://api.colorize.design/getColors?url='
let downloadUrl = 'https://api.colorize.design/saveFile'

let getUrl = () => {
  console.log("Getting Url")
  if (!token.value) {
    hasWarning.value = true
    errorMsg.value = "Please wait while we verify you're human..."
    setTimeout(() => {
      if (!token.value) {
        hasError.value = true
        errorMsg.value = "Verification failed. Please refresh the page and try again."
      }

    }, 15000);
    return
  }

  if(isLoading.value === false){
    if (urlData.value === "") {
      hasError.value = true
      errorMsg.value = urlData.value === "" ? "Please enter a valid URL" : "Please enter a valid domain URL"
      return
    } 

    if (mailingLists.value.find(item => item.id === 1)?.checked === false && mailingLists.value.find(item => item.id === 2)?.checked === false && mailingLists.value.find(item => item.id === 3)?.checked === false && mailingLists.value.find(item => item.id === 4)?.checked === false && mailingLists.value.find(item => item.id === 5)?.checked === false) {
      hasError.value = true
      errorMsg.value = "Please select at least one option from the settings below"
      return
    } 

    resetFiltering()
    let builtUrl = ""
    hasError.value = false
    hasWarning.value = false
    isLoading.value = true
    if(mailingLists.value.find(item => item.id === 2)?.checked){
      screenshotAnimation.value = true
    }
    builtUrl = baseUrl+ urlData.value + '&html=' + mailingLists.value.find(item => item.id === 3)?.checked + '&css=' + mailingLists.value.find(item => item.id === 4)?.checked + '&js=' + mailingLists.value.find(item => item.id === 5)?.checked + '&screenshot=' + mailingLists.value.find(item => item.id === 2)?.checked + '&logo=' + mailingLists.value.find(item => item.id === 1)?.checked + '&screenshotColors=' + mailingLists.value.find(item => item.id === 2)?.currentVal + '&logoColors=' + mailingLists.value.find(item => item.id === 1)?.currentVal
    fetchData(builtUrl)
  }
}

let fetchData = async (url) => {
  
    try {
      try{
        window.clarity("event", "Generate Palette")
        window.clarity("set", "url", url) 
      } catch{

      }
    
    if (scrollElement.value) {
      scrollElement.value.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
  let firstResponse = await fetch(url)

    if (!firstResponse.ok) {
      throw new Error(`${firstResponse.status}, ${await firstResponse.text()}`)
    }
    let data = await firstResponse.json()
    response.nearestColors = data.nearestColors
    response.visualPalette = data.visualPalette
    response.favicon = data.favicon
    response.screenshot = data.screenshot
    isLoading.value = false
    typedUrl.value = urlData.value
    router.push({ path: '/', query: { url: url} });
    setDownloadedImage(data.screenshot);
    title.value = 'Untitled Palette'
  } catch (error) {
    hasError.value = true
    errorMsg.value = error
    isLoading.value = false
    screenshotAnimation.value = false
  }
}

let toggleSettings = () => {
  try{
    window.clarity("event", "Toggle Settings")
  } catch{

  }
  settingToggle.value = !settingToggle.value
}


const downloadFile = async ({ type, category, format }) => {

let formatName = type
let fileName = category
let extension = format
  
  try {
    try{
      window.clarity("event", "Download File ("+extension+")")
    } catch (error) {
    }
   
    const downloadResponse = await fetch(downloadUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ format: formatName, data: JSON.stringify(response) }),
    });
    if (!downloadResponse.ok) {
      throw new Error(`${downloadResponse.status}, ${await downloadResponse.text()}`);
    }
    const blob = await downloadResponse.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `${fileName}.${extension}`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    hasError.value = true;
    errorMsg.value = error;
    isLoading.value = false;
  }
};

const totalVisualPaletteColors = computed(() => {
  if (!response.visualPalette || typeof response.visualPalette !== 'object') {
    return 0;
  }
  return Object.values(response.visualPalette).reduce((acc, colors) => {
    if (Array.isArray(colors)) {
      return acc + colors.length;
    }
    return acc;
  }, 0);
});

const totalCodePaletteColors = computed(() => {
  if (!response.nearestColors) {
    return 0;
  }
  return Object.values(response.nearestColors).reduce((acc, colors) => acc + colors.length, 0);
});

const totalVisualSelectedColors = computed(() => {
  if (!selectedColors.visualPalette || typeof response.visualPalette !== 'object') {
    return 0;
  }
  return Object.values(selectedColors.visualPalette).reduce((acc, colors) => {
    if (Array.isArray(colors)) {
      return acc + colors.length;
    }
    return acc;
  }, 0);
});

const totalCodeSelectedColors = computed(() => {
  if (!selectedColors.nearestColors || typeof selectedColors.nearestColors !== 'object') {
    return 0;
  }
  return Object.values(selectedColors.nearestColors).reduce((acc, colors) => {
    if (Array.isArray(colors)) {
      return acc + colors.length;
    }
    return acc;
    }, 0);
});

const deleteColorVisual = (index, paletteType) => {
    const colorToDelete = response.visualPalette[paletteType][index];
    
    // Remove from response
    response.visualPalette[paletteType].splice(index, 1);
    
    // Remove from selectedColors and update indices
    if (selectedColors.visualPalette[paletteType]) {
        const selectedIndex = selectedColors.visualPalette[paletteType].findIndex(color => 
            color.color === colorToDelete.color && color.name === colorToDelete.name && color.index === index
        );
        if (selectedIndex !== -1) {
            selectedColors.visualPalette[paletteType].splice(selectedIndex, 1);
        }
        
        // Update indices of remaining selected colors
        selectedColors.visualPalette[paletteType].forEach(color => {
            if (color.index > index) {
                color.index--;
            }
        });
    }
    
    // Trigger reactivity
    nextTick(() => {
        response.visualPalette[paletteType] = [...response.visualPalette[paletteType]];
        selectedColors.visualPalette = { ...selectedColors.visualPalette };
    });
}

const deleteColorNearest = (group, index) => {
    const colorToDelete = response.nearestColors[group][index];
    
    // Remove from response
    response.nearestColors[group].splice(index, 1);
    
    if (response.nearestColors[group].length === 0) {
        delete response.nearestColors[group];
    }
    
    // Remove from selectedColors
    if (selectedColors.nearestColors[group]) {
        const selectedIndex = selectedColors.nearestColors[group].findIndex(color => 
            color.color === colorToDelete.color && color.name === colorToDelete.name
        );
        if (selectedIndex !== -1) {
            selectedColors.nearestColors[group].splice(selectedIndex, 1);
        }
        if (selectedColors.nearestColors[group].length === 0) {
            delete selectedColors.nearestColors[group];
        } else {
            // Update indices of remaining selected colors
            selectedColors.nearestColors[group].forEach(color => {
                if (color.index > index) {
                    color.index--;
                }
            });
        }
    }
    
    // Trigger reactivity
    nextTick(() => {
        response.nearestColors = { ...response.nearestColors };
        selectedColors.nearestColors = { ...selectedColors.nearestColors };
    });
}

let openDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value
}

const handleClickOutside = (event) => {
      if (
        dropdown.value &&
        !dropdown.value.contains(event.target) &&
        dropdownButton.value &&
        !dropdownButton.value.contains(event.target)
      ) {
        isDropdownOpen.value = false;
      }
    };
</script>

<style>
/* Scrollbar Styling */
::-webkit-scrollbar {
    width: 12px;
    background-color: #111827;
}
 
::-webkit-scrollbar-track {
    background-color: #111827;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-corner {
  background: rgba(0,0,0,0);
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #6b7280; 
}

.magnifier {
  position: absolute;
  width: 120px;
  border: 2px solid white;
  border-radius: 4px;
  pointer-events: none;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.screenshot-image {
  user-select: none; /* Prevents text selection */
  -webkit-user-drag: none; /* Prevents image dragging on Safari */
  -webkit-touch-callout: none; /* Disables callout, e.g. "Save Image" on iOS */
}
</style>


